<script lang="ts">
  import { AppConfig } from "$lib/config/config"
  const { PricingAvailable, BlogAvailable, AccountAreaEnabled } = AppConfig
  import {
    navbarEnabled,
    footerEnabled,
    headerMenuEnabled,
  } from "$lib/client/LayoutContext"
  import { scrollTo, scrollTop, setGlobalOptions } from "svelte-scrolling"
  import { quadInOut } from "svelte/easing"
  import "$lib/common/css/app.css"

  setGlobalOptions({ offset: -120, duration: 500, easing: quadInOut })

  let showNavbar = false
  let showFooter = false
  let showHeaderMenu = true

  navbarEnabled.subscribe((enabled) => {
    showNavbar = enabled
  })

  footerEnabled.subscribe((enabled) => {
    showFooter = enabled
  })

  headerMenuEnabled.subscribe((enabled) => {
    showHeaderMenu = enabled
  })

  headerMenuEnabled.set(true)
</script>

{#if showNavbar}
  <header class="header flex items-center justify-between p-12 h-[70px]">
    <div>
      <a
        class="normal-case text-xl"
        style="font-size: 1.8rem; font-weight: 700;"
        href="/">{AppConfig.WebsiteName}</a
      >
    </div>

    <div class="flex-grow"></div>
    {#if showHeaderMenu}
      <nav class="nav-links hidden sm:flex space-x-2 text-xl">
        <a use:scrollTo={"home"}>Home</a>
        <a use:scrollTo={"musicians"}>Musicians</a>
        <a use:scrollTo={"venues"}>Venues</a>
        <a use:scrollTo={"fans"}>Music Fans</a>
      </nav>

      <div class="dropdown dropdown-end sm:hidden">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <label tabindex="0" class="btn btn-ghost btn-circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            ><path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h7"
            /></svg
          >
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu menu-lg dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 font-bold"
        >
          <li><a use:scrollTo={"home"}>Home</a></li>
          <li><a use:scrollTo={"musicians"}>Musicians</a></li>
          <li><a use:scrollTo={"venues"}>Venues</a></li>
          <li><a use:scrollTo={"fans"}>Music Fans</a></li>
        </ul>
      </div>
    {/if}
  </header>
{/if}

<div class="content p-0 sm:p-3" id="home">
  <slot />
</div>

<!-- Spacer grows so the footer can be at bottom on short pages -->
<div class="flex-grow"></div>
<div class="">
  <div class="border-t max-w-[1000px] mx-auto"></div>
  {#if showFooter}
    <footer
      class="footer p-10 gap-x-48 lg:gap-x-64 xl:gap-x-96 place-content-center text-base"
    >
      <nav>
        <span class="footer-title opacity-80">Follow us</span>
        <a
          class="link link-hover my-1"
          href="https://www.instagram.com/gig_n_pick/"
          target="_blank"
          rel="noopener noreferrer">Instagram</a
        >
        <!--<a class="link link-hover my-1" href="/contact_us">Contact Us</a>-->
      </nav>
      <aside>
        <span class="hidden footer-title opacity-80">Cool Info</span>
        <span
          class="text-xl md:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent"
          >Gig 'n' Pick</span
        >
        <!--
      Wanna get in touch?
      <a class="link link-hover max-w-[260px] underline" href="/contact_us">
        Drop us a message!
      </a>
      -->
      </aside>
    </footer>
  {/if}
</div>

<style>
  .header {
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    max-width: 100vw;
    box-sizing: border-box;
  }
  .content {
    padding-top: 70px;
  }

  .nav-links a {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
  }
  .nav-links a:hover {
    color: white;
    background-color: #3d0b6e;
  }
</style>
